import React, { useLayoutEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  CardMedia,
  Typography,
  Grid,
  Box,
  Fade,
  Button,
  ButtonGroup,
  CardActions,
  Card,
  CardContent,
  Link,
  Tooltip,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Poduct_List_View(props) {
  const { component, meal, order, deal = false } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const { ready = false } = component.getData("default", {});
  const amount = component.getProductAmount(
    devHelper.getObjectValue(meal, "item._id"),
    order
  );
  const {
    slug,
    name,
    meal_nutrients = [],
    price_coozein,
    trans = [],
  } = devHelper.getObjectValue(meal, "item._values", {});
  const img_version = component.getHelpers("value").getValue("images-version");
  const max_meals = component.getHelpers("value").getValue("max-meals") * 1;
  const lang = component.getHelpers("language").getLang("en");
  const isSpecial =
    component.isAuthenticated() &&
    component.getAuth().getUserAttribute("role") === "special";

  const targetRef = useRef();

  function SetImageHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        if (targetRef.current) {
          setHeight(targetRef.current.clientWidth);
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return height;
  }

  const img_height = SetImageHeight();
  const icons = [
    "fa-regular fa-meat",
    "fa-regular fa-wheat",
    "fa-regular fa-droplet",
  ];

  //css

  const exta_info = {
    mr: 1,
    fontSize: 16,
    lineHeight: 1,
    fontWeight: 500,
    minWidth: "unset",
    p: 0,
    textTransform: "none",
    color: "#000",
    "& i": {
      color: "#FF6F4D",
      mr: 0.5,
    },
    "&:hover": {
      bgcolor: "transparent",
    },
  };

  return (
    <Grid item lg={3} md={4} xs={6}>
      <Card
        elevation={0}
        sx={{
          borderRadius: "12px",
          position: "relative",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          pt: 1,
        }}
      >
        <Box sx={{ px: { xs: 2, md: 4 } }}>
          <Box ref={targetRef}>
            {meal && img_height > 0 && (
              <Fade in={ready}>
                <Link component={RouterLink} to={`/meal/${slug}`}>
                  <CardMedia
                    sx={{
                      maxWidth: "100%",
                      objectFit: "contain",
                      height: { xs: img_height },
                      cursor: "pointer",
                    }}
                    component="img"
                    image={`${filesUrl}/products/webp/${slug}.webp?v=${img_version}`}
                    alt={`${name} image`}
                    onError={(e) => {
                      e.target.src = `${filesUrl}/assets/empty-plate.webp`;
                      component.setData({ "default.ready": true });
                    }}
                    onLoad={() => {
                      component.setData({ "default.ready": true });
                    }}
                  />
                </Link>
              </Fade>
            )}
          </Box>
        </Box>
        {/* price */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            borderBottomLeftRadius: 25,
            bgcolor: "#800080",
            width: 90,
            visibility: price_coozein === 0 ? "hidden" : "visible",
            py: "6px",
          }}
        >
          <Typography
            align="center"
            sx={{
              fontSize: 16,
              fontWeight: 600,
              color: "#fff",
            }}
          >
            +{component.getApp().priceFormater(price_coozein)}
          </Typography>
        </Box>
        {meal && (
          <>
            <CardContent sx={{ px: 1, py: 1, pt: 0 }}>
              <Link
                component={RouterLink}
                to={`/meal/${slug}`}
                underline="none"
              >
                <Typography
                  component="h3"
                  align="center"
                  sx={{
                    color: "#000",
                    fontSize: { xs: 16, sm: 18 },
                    fontWeight: 600,
                    lineHeight: 1.2,
                  }}
                >
                  {trans.length !== 0 &&
                    trans.find((tran) => tran.lang === lang).translation}
                </Typography>
              </Link>
            </CardContent>
            <CardActions
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                mt: "auto",
                px: 1,
                py: 1,
                pt: 0.5,
              }}
            >
              {meal_nutrients
                .filter((nutr) => nutr.item._values.slug === "calories")
                .map((nutr) => {
                  const nutr_slug = devHelper.getObjectValue(
                    nutr,
                    "item._values.slug"
                  );
                  return (
                    <Tooltip
                      key={nutr_slug}
                      title={component.ucfirst(nutr_slug)}
                      placement="top"
                      arrow
                      disableInteractive
                      open={component.getData(
                        `${slug}-${nutr_slug}-tooltip`,
                        false
                      )}
                      onClose={() =>
                        component.setData({
                          [`${slug}-${nutr_slug}-tooltip`]: false,
                        })
                      }
                    >
                      <Button
                        variant="text"
                        sx={{
                          ...exta_info,
                          fontSize: 18,
                          "& i": {
                            color: "#800080",
                            mr: 0.5,
                          },
                        }}
                        disableRipple
                        onClick={() =>
                          component.setData({
                            [`${slug}-${nutr_slug}-tooltip`]: true,
                          })
                        }
                        onMouseEnter={() =>
                          component.setData({
                            [`${slug}-${nutr_slug}-tooltip`]: true,
                          })
                        }
                      >
                        <i className="fa-regular fa-fire" />
                        {`${
                          devHelper
                            .getObjectValue(nutr, "value")
                            .toString()
                            .split(".")[0] || ""
                        }cal `}
                      </Button>
                    </Tooltip>
                  );
                })}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 1,
                  mt: 0.5,
                }}
              >
                {meal_nutrients
                  .filter(
                    (nutr) =>
                      nutr.item._values.slug === "total-fat" ||
                      nutr.item._values.slug === "protein" ||
                      nutr.item._values.slug === "total-carbohydrate"
                  )
                  .sort(
                    (a, b) => b.item._values.position - a.item._values.position
                  )
                  .map((nutr, i) => {
                    const nutr_slug = devHelper.getObjectValue(
                      nutr,
                      "item._values.slug"
                    );

                    return (
                      <Tooltip
                        key={nutr_slug}
                        title={component.ucfirst(nutr_slug)}
                        placement="top"
                        arrow
                        disableInteractive
                        open={component.getData(
                          `${slug}-${nutr_slug}-tooltip`,
                          false
                        )}
                        onClose={() =>
                          component.setData({
                            [`${slug}-${nutr_slug}-tooltip`]: false,
                          })
                        }
                      >
                        <Button
                          variant="text"
                          sx={{
                            ...exta_info,
                          }}
                          disableRipple
                          onClick={() =>
                            component.setData({
                              [`${slug}-${nutr_slug}-tooltip`]: true,
                            })
                          }
                          onMouseEnter={() =>
                            component.setData({
                              [`${slug}-${nutr_slug}-tooltip`]: true,
                            })
                          }
                        >
                          <i className={icons[i]} />
                          {`${
                            devHelper
                              .getObjectValue(nutr, "value")
                              .toString()
                              .split(".")[0] || ""
                          }g`}
                        </Button>
                      </Tooltip>
                    );
                  })}
              </Box>
              {order ? (
                <Button
                  sx={{
                    ...theme.custom.buttons.button2,
                    ...{
                      display: amount !== 0 ? "none" : "inline-flex",
                      mt: 1,
                      textTransform: "uppercase",
                      bgcolor: "#000",
                      borderColor: "#000",
                      fontSize: 16,
                      "&:hover": {
                        bgcolor: "#000",
                      },
                      "&:disabled": {
                        color: "#fff",
                        opacity: 0.8,
                      },
                    },
                  }}
                  disabled={
                    !isSpecial &&
                    deal &&
                    devHelper.getObjectValue(order, "amounts.deals", 0) !== 0 &&
                    devHelper.getObjectValue(order, "amounts.deals", 0) ===
                      max_meals
                  }
                  onClick={() => component.updateCart(meal, 1)}
                >
                  {component.ucfirst("add-to-cart-btn")}
                </Button>
              ) : (
                <Button
                  component={RouterLink}
                  to="/meal-packs"
                  sx={{
                    ...theme.custom.buttons.button2,
                    ...{
                      mt: 2,
                      textTransform: "uppercase",
                      bgcolor: "#000",
                      borderColor: "#000",
                      fontSize: 16,
                      "&:hover": {
                        bgcolor: "#000",
                      },
                    },
                  }}
                >
                  {component.ucfirst("start-now-btn")}
                </Button>
              )}
              <ButtonGroup
                sx={{
                  display: amount === 0 ? "none" : "inline-flex",
                  mt: 1,
                  ml: "0px !important",
                  "& .MuiButton-root": {
                    width: "50px",
                    height: "46px",
                    bgcolor: "#000",
                    color: "#fff",
                    fontSize: 18,
                    fontWeight: 600,
                    border: "1px solid #000",
                    "&:hover": {
                      bgcolor: "#000",
                      borderColor: "#000",
                    },
                    "&:disabled": {
                      color: "#fff",
                    },
                    "& > i": {
                      fontSize: 16,
                    },
                  },
                }}
              >
                <Button
                  sx={{ borderTopLeftRadius: 50, borderBottomLeftRadius: 50 }}
                  onClick={() => {
                    component.updateCart(meal, -1);
                  }}
                >
                  <i className="fa-solid fa-minus" />
                </Button>
                <Button disabled sx={{}}>
                  {amount}
                </Button>
                <Button
                  disabled={
                    !isSpecial &&
                    deal &&
                    devHelper.getObjectValue(order, "amounts.deals", 0) !== 0 &&
                    devHelper.getObjectValue(order, "amounts.deals", 0) ===
                      max_meals
                  }
                  sx={{
                    borderTopRightRadius: 50,
                    borderBottomRightRadius: 50,
                    "&:disabled": {
                      opacity: 0.8,
                    },
                  }}
                  onClick={() => {
                    component.updateCart(meal, 1);
                  }}
                >
                  <i className="fa-solid fa-plus" />
                </Button>
              </ButtonGroup>
            </CardActions>
          </>
        )}
      </Card>
    </Grid>
  );
}
