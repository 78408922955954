import React from "react";
import { useTheme } from "@mui/material/styles";
import { CardMedia, Typography, Box, Paper, Button } from "@mui/material";

export default function PlanDesktop(props) {
  const theme = useTheme();
  const { component, plan, index } = props;
  const { discount = false } = component.props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const { plans = [], discountAllowed = null } = component.getData(
    "default",
    {}
  );
  const company = component.getPage().getParam("company");
  const { name, total, price, min, weekly } = devHelper.getObjectValue(
    plan,
    "_values"
  );

  const showDiscount = discount && discountAllowed;

  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("top-banner").offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <Paper
      elevation={6}
      sx={{
        border: weekly ? "10px solid #993366" : "10px solid #FF6F4D",
        bgcolor: weekly ? "#993366" : "#fff",
        borderRadius: "20px",
        position: "relative",
        textAlign: "center",
        maxWidth: 320,
        height: 370,
        mx: "auto",
        mt: { sm: 10, lg: 4 },
        p: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() =>
        company !== null ? handleClick() : component.selectPlan(plan)
      }
    >
      {weekly ? (
        <>
          <Box
            sx={{
              py: 2,
              border: "1px solid #fff",
              borderRadius: "20px",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                height: 90,
                "& lord-icon": {
                  width: 90,
                  height: 90,
                },
              }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/hffrpcip.json"
                trigger="loop"
                delay="1500"
                colors="primary:#fff,secondary:#fff"
              />
            </Box>
            <Box>
              <Typography
                component="h2"
                sx={{
                  ...theme.custom.titles.title,
                  position: "relative",
                  fontSize: 30,
                  color: "#fff",
                }}
              >
                {component.ucfirst("weekly")}
              </Typography>
              <Typography
                align="center"
                component="h3"
                sx={{
                  ...theme.custom.titles.title,
                  fontSize: 22,
                  color: "#fff",
                }}
              >
                {component.trans("meal-packs")}
              </Typography>
            </Box>
            <Typography
              align="center"
              component="h4"
              sx={{
                ...theme.custom.titles.description,
                fontSize: 16,
                color: "#fff",
                maxWidth: 230,
              }}
            >
              {component.trans("weekly-plan-desc")}
            </Typography>
            <Button
              sx={{
                ...theme.custom.buttons.button3,
                bgcolor: "transparent",
                color: "#fff",
                border: "2px solid #fff",
                "&:hover": {
                  bgcolor: "transparent",
                  color: "#fff",
                },
              }}
            >
              {component.ucfirst("start-now")}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <CardMedia
            component="img"
            image={`${filesUrl}/marketing/bag.webp?v=${img_version}`}
            sx={{
              width: `${80 + (plans.length - index) * 8}px`,
              height: `${80 + (plans.length - index) * 8}px`,
              mx: "auto",
              position: "absolute",
              top: -46 - (plans.length - index) * 8,
              left: "50%",
              transform: "translate(-50%,0)",
            }}
            alt={name}
            title={name}
          />
          <Box
            sx={{
              mt: 5,
              mb: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  height: 90,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  component="h2"
                  sx={{
                    ...theme.custom.titles.title,
                    ...{
                      position: "relative",
                      mx: "auto",
                      lineHeight: 1,
                      fontSize: { xs: 45, md: 55, lg: 60 },
                    },
                  }}
                >
                  {total - min > 0 && (
                    <Typography
                      style={{
                        fontSize: 30,
                        position: "absolute",
                        top: -15,
                        right: -15,
                        fontWeight: 600,
                        color: "#000",
                        lineHeight: 1,
                      }}
                    >
                      +
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      display: showDiscount ? "block" : "none",
                      position: "absolute",
                      top: "50%",
                      right: -80,
                      transform: "translate(0, -50%)",
                      "& i": {
                        fontSize: 24,
                        color: "#FF6F4D",
                      },
                    }}
                  >
                    <i
                      className="fa-solid fa-minus fa-bounce"
                      style={{ fontSize: 16 }}
                    />
                    <i className="fa-solid fa-1 fa-bounce" />
                    <i className="fa-solid fa-0 fa-bounce" />
                    <i className="fa-solid fa-percent fa-bounce" />
                  </Typography>
                  {min}
                </Typography>
              </Box>
              <Typography
                align="center"
                component="h3"
                sx={{
                  ...theme.custom.titles.title,
                  ...{
                    fontSize: {
                      xs: 20,
                      sm: 22,
                      md: 25,
                      lg: 25,
                    },
                  },
                }}
              >
                {component.trans(min > 1 ? "meals" : "meal")}
              </Typography>
              <Typography
                align="center"
                component="h4"
                sx={{
                  ...theme.custom.titles.description,
                  ...{ fontSize: 16 },
                }}
              >
                {component.trans("per-order")}
              </Typography>
              <Typography
                align="center"
                component="h4"
                sx={{
                  ...theme.custom.titles.description,
                  ...{
                    mt: showDiscount ? 1 : 2,
                    fontWeight: 500,
                    position: "relative",
                    lineHeight: 1,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    textAlign: "left",
                  }}
                >
                  <Box
                    sx={{
                      visibility: showDiscount ? "visible" : "hidden",
                      color: "#000",
                      opacity: 0.6,
                      fontSize: 15,
                    }}
                  >
                    <del>{component.getApp().priceFormater(price)}</del>
                  </Box>
                  <Box
                    sx={{
                      color: showDiscount ? "#FF6F4D" : "#000",
                      fontSize: 22,
                      fontWeight: 600,
                    }}
                  >
                    {showDiscount
                      ? component.getApp().priceFormater(price - price * 0.1)
                      : component.getApp().priceFormater(price)}
                  </Box>
                </Box>
                <Box
                  component="span"
                  sx={{
                    ...theme.custom.titles.description,
                    ...{ fontSize: 16 },
                  }}
                >{` / ${component.trans("meal")}`}</Box>
              </Typography>
            </Box>
            <Button
              sx={{
                ...theme.custom.buttons.button2,
                ...{
                  bgcolor: "#000",
                  color: "#fff",
                  border: "2px solid #000",
                  "&:hover": {
                    bgcolor: "#000",
                  },
                },
              }}
            >
              {component.ucfirst("select")}
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
}
